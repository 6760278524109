// @file command palette utils
import { commands as globalCommands } from '@@/bits/global_commands'
import { shuffle, uniq } from 'lodash-es'
import type { Command } from '@@/types'

export let commandsList: Command[] = globalCommands

export interface CommandGroup {
  match: Command[]
  nonMatch: Command[]
}

export const injectCommands = async (commands: Command[]): Promise<Command[]> => {
  commandsList = commandsList.concat(commands)
  return await new Promise((resolve, reject) => {
    resolve(commandsList)
  })
}

export const filterCommandsBySearchTerm = (searchTerm: string): CommandGroup => {
  const normalizedSearchTerm = searchTerm.toLowerCase()
  const commandGroups = { match: new Set<Command>(), nonMatch: new Set<Command>() }

  uniq(shuffle(commandsList)).forEach((command: Command) => {
    if (
      command.name.toLowerCase().includes(normalizedSearchTerm) ||
      command.description.toLowerCase().includes(normalizedSearchTerm)
    ) {
      commandGroups.match.add(command)
    } else {
      commandGroups.nonMatch.add(command)
    }
  })

  const result: CommandGroup = {
    match: Array.from(commandGroups.match),
    nonMatch: Array.from(commandGroups.nonMatch),
  }

  return result
}
